header {
  display: flex;
  margin-bottom: 100px;
  padding-top: 50px;

  .header-space {
    flex: 1;
  }

  .header-logo {
    flex: 1;
    text-align: center;

    .app-icons {
      display: flex;
      justify-content: center;

      img {
        transition: 0.25s all;
        width: 8vw;

        &:hover {
          transform: scale(1.1);
          transition: 0.25s all;
        }
      }
    }

    .main-logo {
      cursor: pointer;
      transition: 0.25s all;
      width: 60%;

      &.--smaller {
        width: 45%;
      }

      &:hover {
        transform: scale(1.1);
        transition: 0.25s all;
      }
    }

    .play-store {
      margin-left: 20px;
    }
  }
}

@media (max-width: 1025px) {
  header {
    .header-space {
      display: none;
    }

    .header-logo {
      .main-logo {
        width: 50%;
      }
    }

    .social-media {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  header {
    margin-bottom: 50px;

    .header-logo {
      .main-logo {
        width: 60%;
      }

      .app-icons {
        img {
          width: 25%;
        }
      }
    }
  }
}
