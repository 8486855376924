.social-media {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: auto;

  img {
    width: 2.5vw;

    &:hover {
      //background-color: #ffd93a;
      //transition: 0.25s all;
    }

    &:not(:first-child) {
      margin-left: 1vw;
    }
  }
}

@media (max-width: 767px) {
  .social-media {
    img {
      width: 10vw;

      &:not(:first-child) {
        margin-left: 5vw;
      }
    }
  }
}
