.homepage {
  section {
    display: flex;
    padding: 0px 100px;

    &.--column {
      flex-direction: column;
    }

    .ar_background {
      position: absolute;
    }

    .ar_phone {
      z-index: 1;
    }

    div {
      display: flex;
      justify-content: center;
      vertical-align: middle;
      margin: auto;

      &.section-wrap-img {
        flex: 3;

        &.--thisrty {
          z-index: 0;
          margin: auto;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 50%;
          padding-left: 100px;
        }
      }

      &.section-presentation {
        flex: 7;

        &.--inverse {
          text-align: right;
        }

        &.--ar-presentation {
          z-index: 0;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 50%;
          padding-left: 100px;
        }

        div {
          display: flex;
          flex-direction: column;
          padding: 50px;
          width: 100%;

          p {
            margin: 0;
          }

          .line_1 {
            font-size: 2.5vw;
            margin-bottom: 10px;
            font-weight: 900;
          }

          .line_2 {
            font-size: 4.9vw;
            margin-bottom: 25px;
            font-weight: 900;
          }

          .line_3,
          .line_4,
          .line_5 {
            font-size: 1.25vw;
          }

          .line_4 {
            margin-bottom: 25px;
          }
        }
      }

      .section-img {
        width: 75%;

        &.--thisrty {
          width: 50%;
        }

        &.--painter,
        &.--fireworks,
        &.--voice,
        &.--selfie,
        &.--picture {
          width: 100%;
          z-index: 1;
        }

        &.--voice {
          margin-left: -300px;
          transform: scale(1.5) translateY(50px);
        }

        &.--picture {
          transform: scale(1.28);
          margin-top: -120px;
        }

        &.--painter {
          transform: scale(1.4);
          padding-left: 150px;
          margin-top: -200px;
        }

        &.--selfie {
          transform: scale(1.4) translateX(100px) translateY(-50px);
        }
      }
    }
  }

  .horizontal-line {
    max-width: 100%;
  }

  .media-gallery {
    margin-bottom: 2.5vw;

    h1 {
      padding-left: 6.5vw;
      font-size: 5.5vw;
    }

    .media-gallery-imgs-wrap {
      padding: 0 6.5vw;

      .media-gallery-main {
        margin: auto;
        margin-bottom: 3vw;

        img {
          width: 100%;
        }
      }

      .media-gallery-other {
        display: flex;

        div {
          flex: 1;

          img {
            width: 100%;
            cursor: pointer;
          }

          &:not(:first-child) {
            margin-left: 3vw;
          }
        }
      }

      h1 {
        font-size: 100px;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 1025px) {
  .homepage {
    section {
      div.section-presentation {
        div {
          .line_3,
          .line_4,
          .line_5 {
            font-size: 2.2vw;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .homepage {
    .media-gallery {
      text-align: center;
      margin-bottom: 5vw;

      h1 {
        font-size: 8vw;
        padding: 0 1vw;
      }
    }

    section {
      padding: 0;
      flex-direction: column-reverse;
      margin-bottom: 50px;
      overflow-x: clip;

      div {
        &.section-wrap-img {
          &.--thisrty {
            width: 100%;
            display: flex;

            img {
              margin: auto;
              margin-top: 15%;
            }
          }
        }
      }

      .desert-image {
        width: 385%;
        margin-left: -700px;
      }

      .ar-image {
        width: 330%;
        margin-left: -450px;
      }

      .ar_background {
        position: relative;
      }

      div {
        margin: 0;

        &.section-presentation {
          &.--desert-presentation {
            position: absolute;
            top: 380px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
          }

          &.--ar-presentation {
            padding-left: 0;
            width: 100%;
            position: absolute;

            div {
              margin: auto;
              margin-top: 15%;
            }
          }

          div {
            padding: 15px;

            p {
              text-align: center;
            }

            .line_1 {
              font-size: 4.5vw;

              &.--section_1 {
                font-size: 6vw;
              }

              &.--section_3 {
                font-size: 6vw;
              }

              &.--section_4 {
                font-size: 6vw;
              }

              &.--section_6 {
                font-size: 6vw;
              }

              &.--section_7 {
                font-size: 6vw;
              }
            }

            .line_2 {
              font-size: 8.7vw;

              &.--section_1 {
                font-size: 11vw;
              }

              &.--section_3 {
                font-size: 11vw;
              }

              &.--section_4 {
                font-size: 11vw;
              }

              &.--section_6 {
                font-size: 11vw;
              }

              &.--section_7 {
                font-size: 11vw;
              }
            }

            .line_3 {
              &.--alt {
                width: 142px;
                margin: auto;
              }
            }

            .line_3,
            .line_4,
            .line_5 {
              font-size: 3.1vw;
            }
          }
        }

        .section-img {
          &.--voice {
            margin-left: 0px;
            transform: none;
          }

          &.--picture {
            margin-top: -100px;
            transform: none;
          }

          &.--painter {
            transform: none;
            padding-left: 0px;
            margin-top: -175px;
          }

          &.--selfie {
            transform: none;
            width: 80%;
            margin-top: -200px;
          }

          &.--mindblown {
            width: 60%;
          }

          &.--fireworks {
            width: 80%;
          }
        }
      }
    }
  }
}
