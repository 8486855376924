.contact-us {
  section {
    margin: 0 5vw;
    display: flex;
    // justify-content: space-between;

    .left-column-wrapper{
      width: 30%;
    }

    h1 {
      font-size: 50px;
    }

    .contact-us-content-1 {
      .links {
        p {
          font-size: 28px;
          text-decoration: underline;
          cursor: pointer;
          transition: 0.25s all;

          &:hover {
            color: #ffd93a;
            transition: 0.25s all;
          }
        }
      }
    }

    .contact-us-content-2 {
      display: flex;
      justify-content: center;
      // width: 60%;
      margin: 2em;
      // margin-top: -280px;

      &.--hide {
        display: none;
      }

      .form-wrap {
        background-color: rgba(54, 103, 186, 0.3);
        // padding: 25px 50px;
        padding: 25px 50px 10px 50px;
        border-radius: 25px;
        // width: 40%;
        text-align: center;

        form {
          .title {
            margin-bottom: 25px;
            text-align: left;
          }

          input {
            padding: 15px 20px;
          }

          textarea {
            padding: 18px 20px;
            height: 150px;
          }

          input,
          textarea {
            font-family: "Comfortaa";
            border-radius: 10px;
            width: -webkit-fill-available;
          }

          .error {
            margin-bottom: 15px;
            text-align: left;
            color: rgb(226, 77, 77);
            font-weight: bold;
          }

          .attach-wrap {
            display: flex;

            .attach {
              margin: 0;
              text-align: left;
              cursor: pointer;

              svg {
                margin-bottom: -2px;
              }
            }
          }

          .files {
            text-align: left;
          }

          .wrap-buttons {
            margin-top: 20px;
            

            .buttons {
              text-align: right;
              margin-top: 20px;

              button {
                padding: 10px 15px;
                background-color: #2961bd;
                color: white;
                border: 0px;
                border-radius: 10px;
                cursor: pointer;
                margin-bottom: 10px;

                &:first-child {
                  margin-right: 10px;
                }
              }
            }
            
          }
        }
      }
    }
  }
}

@media (min-width:1026px){
  .contact-us{
    section{

      .contact-us-content-2{
        .form-wrap{
          .wrap-buttons {
            flex-direction: row;
            display: flex;
            justify-content: space-between;

            .wrap-recap{
              transform: scale(.7);
              transform-origin: 0;
              max-width: 50%;
            }
        }
      }
    }
  }
}
}



@media (max-width: 1025px) {
  .contact-us {
    section {

      display: block;

.left-column-wrapper{
  width:auto;
}

      .contact-us-content-2 {
        width: 100%;
        justify-content: initial;
        margin-top: 0;
        margin-left:0;

        .form-wrap {
          width: 100%;
          padding: 10px 25px;

          .wrap-buttons{
            display: flex;
          align-items: center;
    flex-direction: column;
          }
          
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .contact-us {
    section {
      h1 {
        font-size: 28px;
        text-align: center;
        margin-bottom: 40px;
      }
    }
  }
}
