@font-face {
  font-family: "Comfortaa";
  src: url("/assets/fonts/Comfortaa/static/Comfortaa-Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

.background {
  position: fixed;
  top: 0;
  z-index: -1;
}

body {
  font-family: "Comfortaa";
  color: white;
  background-color: rgb(6, 27, 61);
  background: linear-gradient(180deg, #184080 0%, #061b3d 50%, #184080 100%);
}

.app {
  position: relative;
}

@media (max-width: 767px) {
  .background {
    transform: scale(2) translateX(80px);
    top: 200px;
  }
}
