.tos {
  section {
    margin: 0 10vw;

    h1 {
      font-size: 50px;
    }
  }
}

@media (max-width: 767px) {
  .tos {
    section {
      h1 {
        font-size: 28px;
        text-align: center;
        margin-bottom: 40px;
      }
    }
  }
}
