.faq {
  section {
    margin: 0 10vw;

    h1 {
      font-size: 50px;
    }

    p {
      text-align: center;
    }

    .accordion-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .close {
        color: white;
        opacity: 1;
        text-shadow: 0px;
      }

      .close:hover {
        opacity: 0.75;
      }
    }

    .MuiAccordion-rounded {
      border-radius: 0px;
      margin-bottom: 1px;
      font-weight: 400;
      position: relative;
      width: 100%;
      border: 0;
      font-size: 26px;

      p {
        font-size: 16px;
        padding: 0px;
      }
    }

    .MuiAccordionSummary-content {
      justify-content: space-between;
      margin: 0;

      .open {
        display: block;
      }

      .close {
        display: none;
      }
    }

    .Mui-expanded {
      .open {
        display: none;
      }
      .close {
        display: block;
      }
    }

    .accordion-wrapper {
      width: 100%;
      padding: 25px 0;

      .MuiPaper-elevation1 {
        box-shadow: initial;
      }

      border-bottom: 5px solid rgba(255, 255, 255, 0.1);

      .accordion-inner-wrapper {
        padding: 0px;
        background-color: transparent;

        .faq-section-question {
          color: white;

          div {
            padding: 0;
          }
        }

        .accordian-details {
          color: white;
          padding: 0;

          .faq-section-answer {
            margin: 0;
            text-align: left;

            a {
              color: white;
            }
          }
        }
      }
    }
  }
}

.wrap-see-more {
  text-align: center;
  margin-top: 2.5vw;

  &.--hide {
    display: none;
  }

  .see-more {
    background-color: transparent;
    color: white;
    font-size: 1.5vw;
    border: 2px solid #2a62bf;
    border-radius: 50px;
    padding: 8px 20px;
    transition: all 0.25s;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 250px;
      height: 75px;
      font-size: 6vw;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    &:hover {
      background-color: #2a62bf;
      transition: all 0.25s;
    }
  }
}

.icon-arrow-down {
  opacity: 0.4;
}

@media (max-width: 767px) {
  .faq {
    section {
      h1 {
        font-size: 28px;
        text-align: center;
      }

      .MuiAccordionSummary-content {
        > div {
          font-size: 20px;
        }
      }

      .accordion-wrapper {
        border-bottom: 2px solid rgba(255, 255, 255, 0.1);

        .accordion-inner-wrapper {
          .accordian-details {
            .faq-section-answer {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
