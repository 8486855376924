footer {
  display: flex;
  padding: 2.5vw 3vw;

  .logo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    margin: auto;

    img {
      margin: auto;
      cursor: pointer;
      transition: 0.25s all;
      width: 11vw;

      &:hover {
        transform: scale(1.1);
        transition: 0.25s all;
      }
    }

    p {
      font-size: 1.3vw;
      margin: 0;
      margin-top: 15px;
    }
  }

  .content {
    flex: 5;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-top: auto;

    .content-line-1 {
      display: flex;

      .app-icons {
        img {
          transition: 0.25s all;
          width: 9vw;

          &:hover {
            transform: scale(1.1);
            transition: 0.25s all;
          }
        }

        .play-store {
          margin-left: 20px;
        }
      }

      .social-media {
        flex: initial;
        margin: auto 0;
        margin-left: 1.5vw;
      }

      .languages {
        display: flex;
        margin: auto;
        margin-left: 1.5vw;
        padding-left: 0.5vw;
        border-left: 1px solid white;

        img {
          width: 2.5vw;
          height: 2.5vw;
          border-radius: 50px;
          border: 1px solid transparent;
          cursor: pointer;

          &:first-child {
            margin-left: 1vw;
          }

          &:not(:first-child) {
            margin-left: 1vw;
          }

          &:hover {
            transition: 0.25s all;
            border: 1px solid yellow;
          }
        }
      }
    }

    .content-line-2 {
      margin-top: 2vw;
      font-size: 24px;
      display: flex;
      justify-content: space-between;

      // mobile only
      .languages {
        display: flex;
        margin-left: 1.5vw;
        padding-left: 0.5vw;
        justify-content: center;
        margin-bottom: 20px;

        img {
          width: 7.5vw;
          height: 7.5vw;
          border-radius: 50px;
          border: 1px solid transparent;
          cursor: pointer;

          &:first-child {
            margin-left: 1vw;
          }

          &:not(:first-child) {
            margin-left: 3vw;
          }

          &:hover {
            transition: 0.25s all;
            border: 1px solid yellow;
          }
        }
      }

      .links {
        margin-right: 10px;
        font-size: 1.6vw;
      }

      .back-to-top {
        p {
          display: flex;
          vertical-align: middle;
          margin: 0;
          font-size: 1.2vw;

          .fa-arrow-up {
            margin-right: 20px;
          }

          @media (hover: hover) {
            &:hover {
              color: #ffd93a;
              transition: 0.25s all;
              cursor: pointer;
            }
          }
        }
      }

      span {
        opacity: 0.5;
      }

      a {
        color: white;
        text-decoration: none;
        opacity: 0.5;

        &:hover {
          opacity: 1;
          color: #ffd93a;
          transition: 0.25s all;
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  footer {
    .content {
      .content-line-1 {
        flex-direction: column;

        .app-icons {
          display: flex;
          flex-direction: column;
          margin: auto;

          img {
            width: 100%;
            margin: 0;
            max-width: 150px;

            &:last-child {
              margin: 0;
              margin-top: 15px;
              margin-bottom: 25px;
            }
          }
        }

        .languages {
          width: 100%;
          margin: 0;
          margin-top: 15px;
          border-left: 0px;
          justify-content: center;
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  footer {
    flex-direction: column;
    padding: 25px;

    .logo {
      margin-bottom: 25px;

      img {
        width: 60%;
        padding-left: 10px;
      }

      p {
        font-size: 14px;
      }
    }

    .content {
      margin: 0;

      .content-line-1 {
        flex-direction: column;

        .app-icons {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-bottom: 25px;
          flex-direction: row;

          img {
            width: 30%;

            &:last-child {
              margin: 0;
              margin-left: 15px;
            }
          }
        }

        .social-media {
          width: 100%;
          margin: 0;
          transform: scale(0.75);
        }

        .languages {
          width: 100%;
          margin: 0;
          margin-top: 15px;
          border-left: 0px;
          justify-content: center;
          padding: 0;

          img {
            width: 10vw;
            height: 7.5vw;
          }
        }
      }

      .content-line-2 {
        margin-top: 20px;
        flex-direction: column;
        text-align: center;

        .links {
          margin: 0;
          margin-bottom: 20px;

          span,
          a {
            font-size: 2.6vw;
          }
        }

        .back-to-top {
          display: flex;
          justify-content: center;

          p {
            font-size: 3vw;
            margin-top: 5px;

            .fa-arrow-up {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
